import React from 'react';
import {Skeleton} from '../index';

const TableSkeleton = () => {
  return (
    <div className='flex flex-col gap-4 mt-8'>
      <div className='flex flex-row gap-2 w-full'>
        <Skeleton height={48} width='10%' />
        <Skeleton height={48} width='45%' />
        <Skeleton height={48} width='45%' />
      </div>

      <div className='flex flex-row gap-2 w-full'>
        <Skeleton height={48} width='10%' />
        <Skeleton height={48} width='45%' />
        <Skeleton height={48} width='45%' />
      </div>

      <div className='flex flex-row gap-2 w-full'>
        <Skeleton height={48} width='10%' />
        <Skeleton height={48} width='45%' />
        <Skeleton height={48} width='45%' />
      </div>
    </div>
  );
};

export default TableSkeleton;
