import React, {useRef, useEffect} from 'react';
import {useTable, useRowSelect} from 'react-table';
import {Table, Checkbox} from 'components/ui';

const {Tr, Th, Td, THead, TBody} = Table;

const IndeterminateCheckbox = React.forwardRef(({indeterminate, onChange, ...rest}, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} onChange={(_, e) => onChange(e)} {...rest} />;
});

function DataTable({columns, data, displayCheckBox = true}) {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      if (!displayCheckBox) {
        return;
      }
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '50px',
          Header: ({getToggleAllRowsSelectedProps}) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({row}) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  return (
    <div className='w-full'>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup, index) => (
            <Tr key={`headerGroup-${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <Th key={`headerGroup-${i}`} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
              <Tr key={`row-${i}`} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <Td key={`cell-${index}`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
}

export default DataTable;
