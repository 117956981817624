import React, {lazy, memo, Suspense, useMemo} from 'react';
import {Loading} from 'components/shared';
import {useSelector} from 'react-redux';
import {
  LAYOUT_TYPE_BLANK,
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
} from 'constants/theme.constant';
import useDirection from 'utils/hooks/useDirection';
import useLocale from 'utils/hooks/useLocale';
import {useAuth0} from '@auth0/auth0-react';

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);
  const {isAuthenticated} = useAuth0();

  useDirection();

  useLocale();

  const AppLayout = useMemo(() => {
    if (isAuthenticated) {
      return layouts[layoutType];
    }
    return lazy(() => import('./AuthLayout'));
  }, [layoutType, isAuthenticated]);

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     try {
  //       await checkIsAuthenticated()
  //       await getAuthToken();
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };
  //
  //   getUserMetadata();
  // }, []);

  return (
    <Suspense
      fallback={
        <div className='flex flex-auto flex-col h-[100vh]'>
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
