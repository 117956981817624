import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Router, useNavigate} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import store, {persistor} from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import {QueryClient, QueryClientProvider} from 'react-query';
import './locales';
import {Auth0Provider} from '@auth0/auth0-react';

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
  mockServer({environment});
}

const Auth0ProviderWithRedirectCallback = ({children, ...props}) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function App() {
  const authProvider = appConfig.authProvider;

  const queryClient = new QueryClient();
  const onRedirectCallback = (appState) => {
    Router.replace(appState?.returnTo || '/');
  };
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Auth0ProviderWithRedirectCallback
              domain={process.env.REACT_APP_AUTHORIZATION_DOMAIN}
              clientId={process.env.REACT_APP_AUTHORIZATION_CLIENT_ID}
              cacheLocation={'localstorage'}
              authorizationParams={{
                audience: process.env.REACT_APP_AUDIENCE,
                scope: 'profile email read:users',
                redirect_uri: window.location.origin,
              }}
            >
              <Theme>
                <Layout />
              </Theme>
            </Auth0ProviderWithRedirectCallback>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
