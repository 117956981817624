import React, {useState} from 'react';
import moment from 'moment';
import {HiChevronDown, HiChevronUp, HiCloudDownload} from 'react-icons/hi';
import parser from 'html-react-parser';
import {ActionLink} from '../../shared';
import {Button, Card} from '../index';
import {Tabs} from 'components/ui';
import {BsFiles, BsListColumnsReverse} from 'react-icons/bs';
const {TabNav, TabList, TabContent} = Tabs;

const AccordionHeader = ({onClick, title, isFiles, value, downloadFile}) => {
  const onHeaderButtonClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    downloadFile(value);
  };

  return (
    <div onClick={onClick} className='w-full flex justify-between'>
      <div className='capitalize custom_word_break font-semibold text-lg'>{title}</div>
      {isFiles ? (
        <Button
          variant='twoTone'
          size='sm'
          className='rounded-md w-fit'
          onClick={(e) => onHeaderButtonClick(e)}
          icon={<HiCloudDownload />}
        >
          <a href={value?.download} download={value?.Title} target='_blank' rel='noreferrer'>
            Download
          </a>
        </Button>
      ) : null}
    </div>
  );
};

const TabsAccordionHeader = ({onClick, value, setOpenTab, openTab}) => {
  const onTabClick = (e, index) => {
    if (openTab === index) {
      onClick();
    }
    e?.preventDefault();
    e?.stopPropagation();
    setOpenTab(index);
  };

  return (
    <TabList>
      {Object.keys(value).map((dataKey, index) => (
        <TabNav
          id={`${dataKey}-tab`}
          key={`${dataKey}-tab`}
          value={index}
          className='capitalize'
          icon={dataKey?.toLowerCase() === 'files' ? <BsFiles /> : <BsListColumnsReverse />}
        >
          {dataKey}
        </TabNav>
      ))}
    </TabList>
  );

  // return (
  //   <div onClick={onClick} className='w-full flex justify-between'>
  //     <div className='mb-4 capitalize custom_word_break w-full'>
  //       <ul
  //         className='flex flex-wrap -mb-px text-sm font-medium text-center w-full'
  //         id='myTab'
  //         data-tabs-toggle='#myTabContent'
  //         role='tablist'
  //       >
  //         {Object.keys(value).map((dataKey, index) => (
  //           <li className='mr-2 w-2/5' role='presentation' key={`${dataKey}-li`}>
  //             <button
  //               className='inline-block rounded-t-lg capitalize w-full custom_word_break'
  //               id={`${dataKey}-tab`}
  //               key={`${dataKey}-tab`}
  //               data-tabs-target={`#${dataKey}`}
  //               type='button'
  //               role='tab'
  //               aria-controls={`${dataKey}`}
  //               aria-selected={index === 0}
  //               onClick={(e) => onTabClick(e, index)}
  //             >
  //               {dataKey}
  //             </button>
  //           </li>
  //         ))}
  //       </ul>
  //     </div>
  //   </div>
  // );
};

const AccordionHeaderExtra = ({onClick, expanded}) => (
  <div className='flex-none pl-2' onClick={onClick}>
    {expanded ? <HiChevronUp /> : <HiChevronDown />}
  </div>
);

const TabsAccordion = ({value, title, isArray, downloadFile}) => {
  const [expanded, setExpanded] = useState(true);
  const [openTab, setOpenTab] = useState(0);

  const toggleExpanded = () => setExpanded((current) => !current);

  return (
    <div className='w-full my-4'>
      <Tabs defaultValue={openTab} variant='pill'>
        <Card
          header={
            <TabsAccordionHeader onClick={toggleExpanded} value={value} openTab={openTab} setOpenTab={setOpenTab} />
          }
          headerExtra={<AccordionHeaderExtra onClick={toggleExpanded} expanded={expanded} />}
          headerClass='font-semibold text-lg'
          bodyClass={`p-0 overflow-hidden transition-[max-height] duration-500 ease-in ${
            expanded ? 'max-h-auto' : 'max-h-0'
          }`}
          bordered
        >
          <div id='tabContent' className='flex flex-row flex-wrap w-full p-2'>
            {Object.entries(value).map((content, index) => (
              <TabContent value={index} key={`${content[0]}-tabpanel`}>
                <BodySwitch value={content[1]} title={content[0]} isArray={isArray} downloadFile={downloadFile} />
              </TabContent>
            ))}
          </div>
        </Card>
      </Tabs>
    </div>
  );
  // return (
  //   <div className='w-full my-4'>
  //     <Tabs defaultValue={openTab}>
  //
  //     <Card
  //       header={
  //         <TabsAccordionHeader onClick={toggleExpanded} value={value} openTab={openTab} setOpenTab={setOpenTab} />
  //       }
  //       headerExtra={<AccordionHeaderExtra onClick={toggleExpanded} expanded={expanded} />}
  //       headerClass='font-semibold text-lg'
  //       bodyClass={`p-0 overflow-hidden transition-[max-height] duration-500 ease-in ${
  //         expanded ? 'max-h-auto' : 'max-h-0'
  //       }`}
  //       bordered
  //     >
  //       <div id='tabContent' className='flex flex-row flex-wrap w-full p-2'>
  //         {Object.entries(value).map((content, index) => (
  //
  //           <div
  //             key={`${content[0]}-tabpanel`}
  //             className={`${openTab === index ? 'block' : 'hidden'} rounded-lg`}
  //             id={content[0]}
  //             role='tabpanel'
  //             aria-labelledby={`${content[0]}-tab`}
  //           >
  //             <BodySwitch value={content[1]} title={content[0]} isArray={isArray} downloadFile={downloadFile} />
  //           </div>
  //         ))}
  //       </div>
  //     </Card>
  //     </Tabs>
  //   </div>
  // );
};

const Accordion = ({entry, isArray, isFiles, downloadFile}) => {
  const [key, value] = entry;
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => setExpanded((current) => !current);
  return (
    <div className='w-full my-4'>
      <Card
        header={
          <AccordionHeader
            onClick={toggleExpanded}
            title={key}
            isFiles={isFiles}
            value={value}
            downloadFile={downloadFile}
          />
        }
        headerExtra={<AccordionHeaderExtra onClick={toggleExpanded} expanded={expanded} />}
        headerClass='font-semibold text-lg'
        bodyClass={`p-0 overflow-hidden transition-[max-height] duration-500 ease-in ${
          expanded ? 'max-h-auto' : 'max-h-0'
        }`}
        bordered
      >
        <div className='flex flex-row flex-wrap w-full p-2'>
          <BodySwitch value={value} title={key} isArray={isArray} downloadFile={downloadFile} />
        </div>
      </Card>
    </div>
  );
};

const checkDataContainsFile = (data, title) => {
  return Object.keys(data).includes('files');
};

const BodySwitch = ({value, title, downloadFile}) => {
  if (checkDataContainsFile(value, title)) {
    return <TabsAccordion value={value} title={title} downloadFile={downloadFile} isArray={false} />;
  }

  if (value instanceof Array) {
    return value.map((item, index) => (
      <ContentBodyMapper
        key={`ContentBodyMapper-${index}`}
        content={[`${index + 1}. ${title}`, item]}
        isArray={value?.length > 1}
        isFiles={title === 'files'}
        downloadFile={downloadFile}
      />
    ));
  }

  return Object.entries(value).map((content, index) => (
    <ContentBodyMapper
      key={`ContentBodyMapper-${index}`}
      content={content}
      isFiles={title === 'files'}
      downloadFile={downloadFile}
    />
  ));
};

const checkIsValueLink = (value) => {
  return value?.toString()?.includes('<a');
};

const checkIsValueDate = ({key, value}) => {
  if (key?.toString()?.toLowerCase()?.includes('date') && moment(value).isValid()) {
    return moment(value).format('DD/MM/YYYY');
  }
  return value;
};

const BasicGrid = ({content}) => {
  const [key, value] = content;
  if (key?.includes('__hidden')) {
    return;
  }
  return (
    <div className='flex flex-row basis-1/2 gap-2 p-2 border-b-2'>
      <div className='basis-1/2 min-w-min'>{key}:</div>
      {checkIsValueLink(value || '') ? (
        <ActionLink className='basis-1/2 custom_word_break'>{parser(value || '')}</ActionLink>
      ) : (
        <div className='basis-1/2 custom_word_break'>{checkIsValueDate({key, value})}</div>
      )}
    </div>
  );
};

const RowObject = ({content, isArray, isFiles, downloadFile}) => {
  return (
    // <div className={`order-last p-4 mt-2 ${isArray || isFiles ? 'xl:w-1/2' : 'w-full'}`}>
    <div className={`order-last p-4 mt-2 w-full`}>
      <Accordion entry={content} isArray={isArray} isFiles={isFiles} downloadFile={downloadFile} />
    </div>
  );
};

const RowTable = ({content, isArray, isFiles, downloadFile}) => {
  return (
    <div className={`order-last p-4 mt-2`}>
      <Accordion entry={content} isArray={isArray} isFiles={isFiles} downloadFile={downloadFile} />
    </div>
  );
};

const ContentBodyMapper = ({content, isArray, isFiles, downloadFile}) => {
  const [key, value] = content;

  if (value === null || value === undefined) {
    return <BasicGrid content={content} key={key} downloadFile={downloadFile} />;
  }

  if (value instanceof Array) {
    return <RowTable content={content} isFiles={isFiles} downloadFile={downloadFile} />;
  }

  if (typeof value === 'object') {
    return <RowObject content={content} isArray={isArray} isFiles={isFiles} downloadFile={downloadFile} />;
  }

  return <BasicGrid content={content} />;
};

const NestedAccordion = ({data, downloadFile}) => {
  return Object.entries(data).map((entry, index) => (
    <Accordion key={`Accordion-${index}`} entry={entry} downloadFile={downloadFile} />
  ));
};

export default NestedAccordion;
