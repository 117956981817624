const appConfig = {
  apiPrefix: process.env.REACT_APP_API_PREFIX || '/api',
  collectionApiPrefix: process.env.REACT_APP_COLLECTION_API_PREFIX,
  authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  enableMock: false,
  apiKey: process.env.REACT_APP_API_KEY,
  awsAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  awsSecretKey: process.env.REACT_APP_AWS_SECRET_KEY,
  awsRegion: process.env.REACT_APP_AWS_REGION,
  contentSize: process.env.REACT_APP_CONTENT_SIZE,
  s3Bucket: process.env.REACT_APP_S3_BUCKET,
  s3Prefix: process.env.REACT_APP_S3_PREFIX,
  authProvider: {
    domain: process.env.REACT_APP_AUTHORIZATION_DOMAIN,
    clientId: process.env.REACT_APP_AUTHORIZATION_CLIENT_ID,
    useRefreshTokens: process.env.REACT_APP_AUTHORIZATION_USE_REFRESH_TOKENS,
    useRefreshTokensFallback: process.env.REACT_APP_AUTHORIZATION_USE_REFRESH_TOKENS_FALLBACK,
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUDIENCE,
      max_age: 12 * 60 * 60,
    },
  },
};

export default appConfig;
