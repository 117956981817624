import {createSlice} from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    token: '',
    signedIn: false,
    isAuthenticated: false,
    refreshTokenValidity: false,
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
      state.token = '';
      state.refreshTokenValidity = false;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
      state.refreshTokenValidity = action.payload;
    },
  },
});

export const {onSignInSuccess, onSignOutSuccess, setToken, setAuthenticated} = sessionSlice.actions;

export default sessionSlice.reducer;
